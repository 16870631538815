table {
  // display: grid;
  // grid-template-columns: 101.8px repeat(47, 1fr);
  // grid-template-rows: 20px;
  box-sizing: border-box;
  font-size: 0.7rem;
  

  user-select: none;

  &.mtop{
    margin-top: 84px;
  }
  td {
    width: 35px;
    min-width: 35px !important;
    height: 27px;
    overflow: visible;
    div {
      position: relative;
      height: 100%;
      top: -1px;
      left: -1px;

      // top: -7px;
      span {
        display: block;
        position: absolute;
        height: 0px;
        // min-width: 120px;
        // left: 5px;
      }
    }

  }

  td:hover {
    outline-style: dashed;
    outline-width: 2px;
    outline-offset: -2px;
    outline-color: #2196F3;
  }

  .border-top {
    border-top: 0.3px solid #000 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    border-bottom: none;
  }

  .border-bottom-gray {
    border-bottom: 0.3px solid#454545;
  }

  .border-bottom-small {
    border-bottom: 0.1px solid#45454512;
  }

  .border-right {
    border-right: 1px solid #333 !important;
  }

  .full-border {
    border: 1px solid #454545;
  }

  .name {
    height: 22px;
    min-width: 101.8px !important;
  }

  .hover:hover {
    background-color: rgba(250, 146, 250, 0.842) !important;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

ul {
  list-style: none;
  padding-inline-start: 4px;

  li {
    list-style: none;
  }
}

.input-group-text {
  min-width: 101.8px;
  display: inline-block;
}


.justify-center {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100%;
  display: block;
  position: relative;
  top: -8px;
  text-align: center;
}

.color-show {
  display: inline-block;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 6px;

}


.fix-width-175{
  width: 175px;
}

.react-date-picker__wrapper{
  border: none !important;
}

.tools-grid{
  background-color: white;
  font-size: .7rem;
  max-width: 1775px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  div {
    text-align: center;
    padding: 5px 0;
  }
}

.hours-grid{
  background-color: white;
  font-size: .7rem;
  display: grid;
  grid-template-columns: 101.8px repeat(47, 35px);
  div{
    border: 1px solid #ccc;
    text-align: center;
    padding: 5px 0;
  }
}


.nav-color-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.menu {
  padding: 5px 1px;
  background-color: white;
  border-radius: 6px;
  z-index: 900;
  border: 1px solid #ccc;
  width: 225px;
  height: 307px;
  overflow-x: hidden;
  overflow-y: scroll;

  li {
    padding: 2px 5px;
    display: flex;
    // justify-content: center;
    align-items: center;

    .a {
      display: inline-block;
      padding: 6px;
      border: 1px solid #ccc;
      border-radius: 50%;
      margin-right: 3px;
    }

    .b {
      display: inline-block;
      width: 195px;
      text-align: right;
    }

    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #e3ecf1;
  }
}



td:focus,
div:focus {
  outline: none !important
}