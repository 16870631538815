.c-navbar {
  width: 300px;
  height: 30px;
  border-radius: 0 0 5px 5px;
  font-size: 16px;
  font-weight: bold;
  background: white;
  color: #282C34;
  top: 0;
  padding-top: 5px;
}
