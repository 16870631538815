.c-control {
  font-size: 16px;
  border-radius: 5px;
  background: white;
  color: #282C34;
  margin: 20px;
  padding: 10px;

  & > select {
    height: 30px;
    width: 135px;
    margin-right: 10px;

    &:focus {
      outline: 0;
    }
  }

  & > button {
    height: 30px;
    width: 135px;
    border: 1px solid;

    &:hover {
      cursor: pointer;
      background: #dddcdc;
    }

    &:focus {
      outline: 0;
    }
  }
}